
export function common () {
    var btn = document.querySelector('.js-toggle-menu'),
        menu = document.querySelector('.js-menu'),
        //header = document.querySelector('#header'),
        menuHeight;
    var height;
    
    function setARIAState(menuState) {
        var state = menuState === 'show' ? true : false;

        menu.setAttribute('aria-expanded', state);
        btn.setAttribute('aria-expanded', state);
    }

    function toggleMenu() {
        if (menu.classList.contains('is-hide')) {
            // 非表示→表示
            btn.classList.add('collapsed');
            menu.classList.remove('is-hide');
            menu.classList.add('is-show');
            $('.toggle-txt').text('CLOSE');
            menuHeight = menu.children[0].offsetHeight;
            menu.style.maxHeight = menuHeight + 'px';
            setARIAState('show');
        } else {
            // 表示->非表示
            menu.style.maxHeight = '';
            menu.classList.remove('is-show');
            menu.classList.add('is-slideup');
            btn.classList.remove('collapsed');
            $('.toggle-txt').text('MENU');
            setARIAState();
        }
    }

    function onSlideUp() {
        if (menu.classList.contains('is-slideup')) {
            menu.classList.add('is-hide');
            menu.classList.remove('is-slideup');
        }
    }

    menu.classList.add('nav-menu-enable');
    menu.classList.add('is-hide');
    menu.addEventListener('webkitTransitionEnd', onSlideUp);
    menu.addEventListener('transitionend', onSlideUp);

    btn.addEventListener('click', toggleMenu);
    btn.setAttribute('aria-controls', menu.id);
    setARIAState();

    $('.close').on('click', function ()  {
        $('.js-menu').css({
            'max-height': 0
        });
        $('.js-menu').removeClass('is-show');
        $('.js-menu').addClass('is-slideup');
        $('.js-toggle-menu').removeClass('collapsed');
        $('.toggle-txt').text('MENU');
        setARIAState();
    });

    if ($(window).width() > 767 && $(window).width() < 905) {
        height = 72;
    } else if ($(window).width() < 767) {
        height = 0;
    } else {
        height = 40;
    }

    $('a[href^=\'#\']').on('click', function (event) {
        event.preventDefault();

        var url = this.href;

        var parts = url.split('#');
        var target = parts[1];

        var target_offset = $('#' + target).offset();
        var target_top = target_offset.top - height;

        $('html, body').animate({
            scrollTop: target_top
        }, 500);
    });

    window.onload = function() {
        if ($(window).width() > 767 && $(window).width() < 905) {
            height = 72;
        } else if ($(window).width() < 767) {
            height = 0;
        } else {
            height = 40;
        }

        if (window.location.hash) {
            setTimeout(function () {
                $('html, body').scrollTop(0).show();
                $('html, body').animate({
                    scrollTop: $(window.location.hash).offset().top - height
                }, 500);
            }, 0);
        }
        else {
            $('html, body').show();
        }

    };
}
