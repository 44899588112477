import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import './css/reset.css';
import './app.scss'; 
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

import '@fontsource/homemade-apple'; 


import { common } from './js/common.js';
common();
import objectFitImages from 'object-fit-images';
objectFitImages('img.c_ph');
import 'jquery-parallax.js';